
<template>
  <comprehensive>
    <div
      style="background: #f5f5f5;"
      v-loading="pageLoading"
    >
      <div class="title_img_div">
        <div class="div1">
          <div class="nav_box">
            <span @click="onGoHome">首页</span> > <span @click="onGoBid">集采专场</span> >
            <span>竞价专场详情</span>
          </div>
          <div class="div2">
            <div class="div3">
              <div class="div4">
                <span>{{ data.activityName }}</span>
                <!--竞价结束-->
                <!--评标完成 流标-->
                <!-- 1-未开始 2-竞价中 3-竞价结束/待评标 4-竞价结束/评标审批中 5-竞价结束/评标审批完成/已评 6-竞价结束/评标审批完成/流标 7-竞价结束/流标 -->
                <div
                  class="div5-67"
                  v-if="data.activityStatus == 6 || data.activityStatus == 7"
                ></div>
                <div
                  class="div5-3"
                  v-if="data.activityStatus == 3"
                ></div>
                <div
                  class="div5-4"
                  v-if="data.activityStatus == 4"
                ></div>
                <div
                  class="div5-2"
                  v-if="data.activityStatus == 2"
                ></div>
                <div
                  class="div5-5"
                  v-if="data.activityStatus == 5"
                ></div>
              </div>
              <div class="div6">专场编号 : {{ data.activityCode }}</div>
            </div>
            <div
              class="div7"
              v-if="data.activityStatus == 1 || data.activityStatus == 2"
            >
              <div
                class="div8"
                v-if="data.activityStatus == 1"
              >距离开始</div>
              <div
                class="div8"
                v-if="data.activityStatus == 2"
              >距离结束</div>
              <div class="div9">
                <span class="spanh5">{{ (timeStr2 && timeStr2.day) ? timeStr2.day : '' }}</span>天 <span
                  class="spanh5">{{ (timeStr2 && timeStr2.hour) ? timeStr2.hour : ''}}</span>时 <span
                  class="spanh5">{{ (timeStr2 && timeStr2.minute) ? timeStr2.minute : ''}}</span>分 <span
                  class="spanh5">{{ (timeStr2 && timeStr2.second) ? timeStr2.second : '' }}</span>秒
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="title_text_div">
        <div class="div1">
          <div class="div2">
            <div class="div3">
              <div class="div4">竞价开始时间</div>
              <div class="div5">{{ data.activateTimeStart }}</div>
            </div>
            <div class="div3">
              <div class="div4">竞价截止时间</div>
              <div class="div5">{{data.activateDelayedEnd }}</div>
            </div>
            <div class="div3">
              <div class="div4">超时竞价延时</div>
              <div class="div5">{{ data.activateDelayedTime }}秒</div>
              <div class="div6">
                最后60秒内供应商出价则自动按该时长延长竞价时间
              </div>
            </div>
            <div class="div3">
              <div class="div4">参与方式</div>
              <div class="div5">
                <span v-if=" data.joinMode == '1'">公开</span>
                <span v-if=" data.joinMode == '2'">定向</span>
              </div>
            </div>
          </div>
          <div
            class="div2"
            style="margin-top: 15px"
          >
            <div class="div3">
              <div class="div4">报价方式</div>
              <div
                class="div5"
                v-if="data.bidMode == 1"
              >一口价</div>
              <div
                class="div5"
                v-if="data.bidMode == 2"
              >网价</div>
              <div class="div6">
                按现货现款价报价，如产生垫资，结算按垫资加价标准处理
              </div>
            </div>
            <div
              class="div3"
              v-if="data.include_fee == 1"
            >
              <div class="div4">出价要求</div>
              <div class="div5">供应商出价为含运杂费的包到价</div>
            </div>
            <div class="div3">
              <div class="div4">结算方式</div>
              <div class="div5">{{ data.settlementMethod }}</div>
            </div>
          </div>
          <div
            class="div2"
            style="margin-top: 15px; border: none"
          >
            <div class="div3">
              <div class="div4">减价梯度</div>
              <div class="div5">{{ data.markDownStep }}元/吨</div>
              <div class="div6">
                供应商可多次出价，后一次出价必须按该梯度减价报价
              </div>
            </div>
            <div class="div3">
              <div class="div4">竞价项目</div>
              <div
                class="div5"
                v-if="data.projectName == ''"
              >无</div>
              <div
                class="div5"
                v-else
              >{{ data.projectName }}</div>
            </div>
            <div class="div3">
              <div class="div4">提货方式</div>
              <div class="div5">{{data.deliveryMethodName}}</div>
              <!--              <div class="div5" v-if="data.deliveryMethod == '1'">自提</div>-->
              <!--              <div class="div5" v-if="data.deliveryMethod == '2'">配送</div>-->
            </div>
            <div class="div3">
              <div class="div4">交货地址</div>
              <div class="div5">{{ data.deliveryAddressFull }}</div>
            </div>
          </div>

          <div
            style="margin-left: 110px; margin-top: 50px"
            v-if="data.activityStatus == 2 && type"
          >
            <button
              class="btn gojj"
              @click="openOffer"
            >立即竞价</button>
          </div>
        </div>
        <div class="divr1">
          <div class="divr2">采购单位：{{ data.purchaseCompanyName }}</div>
          <div class="divr3">
            <img src="./img/82646.svg" />
            <div class="divr4">
              <span>联系人:</span>
              <span style="text-align:left">{{ data.userName }}</span>
            </div>
            <div
              class="divr4"
              style="margin-top: 5px"
            >
              <span>联系电话:</span>
              <span style="text-align:left">{{ data.userMobile }}</span>
            </div>
          </div>
          <div
            v-if="type"
            class="divr5"
          >
            <span></span>
          </div>
          <div
            v-if="!type"
            class="divr6"
          >
            <span v-if="data.rankingsSequence == null">暂无排名，赶紧提交报价吧</span>
            <span v-else>第<span
                style="font-size: 24px;color: #FF7E00;margin: 0px 10px;"
                class="text"
              >{{data.rankingsSequence}}</span>名</span>
          </div>
        </div>
      </div>

      <div
        class="divb1"
        style="margin-bottom: 30px"
        v-if="data.activityDesc"
      >
        <div class="divb2">
          <div class="divb3"> </div>
          竞价补充说明
        </div>
        <textarea
          name="remarks"
          style="width: 1090px; height: 115px; resize: none;  border-color: #e5e5e5;padding: 15px 15px; "
          disabled
          v-model="data.activityDesc"
        ></textarea>
      </div>

      <div class="divb1">
        <div
          class="divb2"
          v-if="type"
        >
          <div class="divb3"></div>
          竞价物资
        </div>
        <div
          class="divb2"
          v-if="!type"
        >
          <div class="divb3"></div>
          立即报价
          <span style="
              margin-left: 20px;
              font-size: 14px;
              color: #999999;
              line-height: 29px;
            ">请以现款现货报价，提交报价前请确认已充分了解竞价信息。</span>
        </div>
        <div
          v-if="!type"
          style="
            width: 1120px;
            height: 100px;
            font-size: 14px;
            color: #333333;
            display: flex;
          "
        >
          <span style="line-height: 100px">批量报价</span>
          <div style="width: 310px; height: 32px; margin-left: 50px">
            <span style="
                display: inline-block;
                height: 50px;
                line-height: 50px;
                margin-top: 25px;
                margin-right: 10px;
              ">品名 :
            </span>
            <el-select
              v-model="p_name"
              multiple
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="item in PMlist"
                :key="item.id"
                :label="item.id"
                :value="item.name"
              >
                <span>{{ item.name }}</span>
              </el-option>
            </el-select>
            <!-- <el-select
              id="bat_select"
              multiple
              collapse-tags
              v-model="p_name"
            >
              <el-option
                v-for="(item,index) in PMlist"
                :key="index"
              >{{ item.name }}</el-option>
            </el-select> -->
          </div>
          <div style="width: 210px; height: 100px; margin-left: 20px">
            <span style="
                display: inline-block;
                height: 50px;
                line-height: 50px;
                margin-top: 25px;
              ">产地 :
            </span>
            <input
              class="form-control origin_place"
              placeholder="请输入"
              style="
                border-color: #dcdcdc;
                width: 144px;
                height: 26px;
                display: inline-block;
                margin-top: 25px;
                border-radius: 2px;
                box-shadow: none;
                padding: 0px;
                padding-left: 10px;
                margin-left: 5px;
              "
              v-model="cd_name"
            />
          </div>
          <div style="width: 270px; height: 100px; margin-left: 20px">
            <span style="
                display: inline-block;
                height: 50px;
                line-height: 50px;
                margin-top: 25px;
                margin-left: 5px;
              ">报价 :
              <span style="color: #333333; margin: 0px 8px">一口价</span></span>
            <input
              v-model="jg_name"
              class="form-control price"
              placeholder="请输入"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
              onafterpaste="this.value=this.value.replace(/[^\d.]/g,'')"
              style="
                border-color: #dcdcdc;
                width: 144px;
                height: 26px;
                display: inline-block;
                margin-top: 25px;
                border-radius: 2px;
                box-shadow: none;
                padding: 0px;
                padding-left: 10px;
                margin-left: 5px;
              "
            />
          </div>
          <div style="width: 110px; height: 100px; margin-left: 40px">
            <button
              @click="plfz"
              class="bat_btn btn"
              style="
                background-color: #e73352;
                color: #ffffff;
                width: 100px;
                height: 32px;
                border-radius: 2px;
                font-size: 16px;
                margin-top: 35px;
                line-height: 18px;
              "
            >
              批量赋值
            </button>
          </div>
        </div>
        <div class="divb4">
          <table class="table table-hover">
            <tbody>
              <tr>
                <th>物资大类</th>
                <th style="min-width: 190px">物资信息</th>
                <th>计价方式</th>
                <th style="
                    text-align: right !important;
                    padding-left: 0px !important;
                  ">
                  采购量
                </th>
                <th style="
                    text-align: right !important;
                    padding-right: 20px !important;
                  ">
                  最高限价(元)
                </th>
                <th
                  v-if="!type"
                  style="text-align: center"
                >产地</th>
                <th
                  v-if="!type"
                  style="text-align: center"
                >单价</th>
                <th
                  v-if="!type"
                  style="text-align: center"
                >总金额</th>
              </tr>
              <tr
                v-for="(item,index) in dataDetails"
                :key="index"
              >
                <td>{{ item.category }}</td>
                <td style="min-width: 190px">
                  {{ item.productName }} {{ item.material }}
                  {{ item.specifications }} {{ item.originPlace }}
                </td>
                <td>{{ item.measurementMethod }}</td>
                <td
                  v-if="item.measurementMethod != '尺量'"
                  style="
                    text-align: right !important;
                    padding-left: 0px !important;
                  "
                >
                  {{ item.quantity }}{{ item.measurementUnit }}/{{item.weight }}吨
                  <!--                  {{ item.quantity }}{{ item.measurementUnit }}-->
                </td>
                <td
                  v-else
                  style="
                    text-align: right !important;
                    padding-left: 0px !important;
                  "
                >
                  {{ item.quantity }}{{ item.measurementUnit }}/{{item.weight }}吨
                </td>
                <td style="
                    text-align: right !important;
                    padding-right: 20px !important;
                  ">
                  <span v-if="item.ceilingPrice > 0">{{item.ceilingPrice}}</span>
                  <span v-else>--</span>
                </td>
                <td v-if="!type">
                  <input
                    autocomplete="off"
                    class="form-control origin_place_item"
                    style="
                      width: 70px;
                      border-color: #e5e5e5;
                      box-shadow: none;
                      border-radius: 2px;
                      text-align: center;
                    "
                    v-model="item.offerOriginPlace"
                  />
                </td>
                <td v-if="!type">
                  <input
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                    onafterpaste="this.value=this.value.replace(/[^\d.]/g,'')"
                    data-pre-val="100.00"
                    class="form-control price_item bid_format-price"
                    autocomplete="off"
                    style="
                      width: 70px;
                      border-color: #e5e5e5;
                      border-radius: 2px;
                      box-shadow: none;
                      text-align: center;
                    "
                    v-model="item.offerPrice"
                    @change="priceChange(item)"
                  />
                </td>
                <td v-if="!type">
                  <input
                    disabled
                    readonly=""
                    data-sum-type="backend"
                    class="form-control format-amount"
                    autocomplete="off"
                    style="
                      width: 70px;
                      border-color: #e5e5e5;
                      box-shadow: none;
                      border-radius: 2px;
                      text-align: center;
                      background: #e5e5e5;
                    "
                    v-model="item.offerAmount"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="
            width: 1120px;
            height: 50px;
            line-height: 50px;
            background-color: #f6f6f6;
            border: 1px solid #efefef;
            border-top: none;
            text-align: right;
          ">
          <span style="font-size: 14px; color: #666666; margin-right: 16px">合计</span>
          <span style="font-size: 14px; color: #999999; margin-right: 79px">重量
            <span style="color: #ef2147; font-weight: bold; font-size: 16px">{{data.weightTotel}}</span></span>
          <span
            style="font-size: 14px; color: #999999; margin-right: 79px"
            v-if="!type"
          >金额
            <span style="color: #ef2147; font-weight: bold; font-size: 16px">{{data.amountTotel}}</span></span>
        </div>
      </div>
      <div
        style="width: 1200px; margin: 0px auto; position: relative"
        v-if="!type"
      >
        <div style="font-size: 14px; color: #333333; margin-bottom: 14px">
          报价说明 :
        </div>
        <textarea
          id="remarks"
          name="remarks"
          style="
            width: 1170px;
            height: 115px;
            resize: none;
            border-color: #e5e5e5;
            padding: 15px 15px;
          "
          placeholder="请补充竞价说明相关信息..."
          @input="fontChange"
          v-model="offer_comment"
        ></textarea>
        <span
          class="msglen_activity_remarks"
          style="
            bottom: 83px;
            width: 58px;
            /* left: 0px; */
            display: inline-block;
            text-align: right;
            position: absolute;
            color: rgb(153, 153, 153);
            font-size: 12px;
            right: 10px;
          "
        >{{ font_length }}/200</span>
        <div style="
            width: 1200px;
            height: 40px;
            margin-top: 32px;
            text-align: center;
            margin-bottom: 40px;
          ">
          <div
            class="btn price_btn"
            id="tijiaoBaojia"
            style="
              border: 1px solid #e73352;
              color: #ffffff;
              background-color: #e73352;
              width: 110px;
              border-radius: 2px;
              font-size: 14px;
              margin-right: 20px;
            "
            @click="upData"
          >
            提交报价
          </div>
          <div
            id="fanhui"
            class="btn"
            href="/index/bid/index.html"
            style="
              border: 1px solid #dddddd;
              color: #ef2147;
              width: 110px;
              border-radius: 2px;
              font-size: 14px;
              background-color: #ffffff;
            "
            @click="goback"
          >
            返回
          </div>
        </div>
      </div>

      <div
        v-if="!type && data.offerHistory && data.offerHistory.length > 0"
        style=" width: 1120px;min-height:255px;background-color: #ffffff;margin:15px auto;padding: 24px 40px;"
      >
        <h4 style="margin: 0;font-size: 20px;border-bottom: 1px solid  #f6f6f6;padding-bottom: 22px;">
          我的出价记录
        </h4>
        <div style="width: 1120px;min-height:166px;line-height: 100px;font-size: 16px;color: #333333;margin-top: 26px;">
          <table
            class="table"
            style="border: 1px solid #DCDCDC;width: 100%; max-width: 100%;"
          >
            <tr style="background-color: #F6F6F6;height:50px;line-height: 50px;padding: 0!important; color: #333333; font-size: 14px;">
              <th style="padding-left: 32px!important;text-align: left!important;">出价时间</th>
              <th style="padding-left: 32px!important;text-align: left!important;">报价方式</th>
              <th style="padding-left: 32px!important;text-align: left!important;">总额</th>
              <th style="padding-left: 32px!important;text-align: left!important;">报价说明</th>
              <th>操作</th>
            </tr>
            <tr
              v-for="(item,index) in data.offerHistory"
              style="height:50px;line-height: 50px;padding: 0!important; color: #333333;font-size: 14px;"
            >
              <td style="padding-left: 32px!important;text-align: left!important;">{{item.offerTime}}</td>
              <td style="padding-left: 32px!important;text-align: left!important;">
                <span v-if="item.offerMode == 1">一口价</span>
                <span v-if="item.offerMode == 2">网价浮动</span>
              </td>
              <td style="padding-left: 32px!important;text-align: left!important;">{{item.offerAmountSum}}</td>
              <td style="padding-left: 32px!important;text-align: left!important;">{{item.offerComment}}</td>
              <td
                style="color: #e73352;cursor:pointer;text-align: center"
                @click="bidDetails(item)"
              >出价明细</td>
            </tr>
          </table>
        </div>
      </div>

      <div
        v-if="data.activityStatus == 5"
        style="width: 1120px;background-color: #ffffff;margin:0px auto;padding: 24px 40px;margin-bottom: 14px"
      >
        <h4 style="margin: 0;font-size: 20px;color:#333333;border-bottom: 1px solid #efefef;padding-bottom: 22px;">
          评标结果公示
        </h4>
        <!-- 状态 1-未开始 2-竞价中 3-竞价结束/待评标 4-竞价结束/评标审批中 5-竞价结束/评标审批完联系人成/已评 6-竞价结束/评标审批完成/流标 7-竞价结束/流标 -->
        <div
          style="width:581px;height: 185px;background: url(https://ddmallimg.ddmg.com/public/assets/img/newbid/772716.png) no-repeat center;margin:25px auto;"
        >
          <h4 style="color: #FF7E00;font-size: 20px;padding-left: 138px;padding-top: 70px;padding-right: 10px;">
            中标单位:
            {{data.winnerCompanyName}}
          </h4>
          <h5 style="color: #999999;font-size: 14px;padding-left: 138px;padding-top: 10px;">公示时间 :{{data.appraiseTime}}</h5>
        </div>
      </div>

      <div style="width: 1200px; margin: 0 auto; background-color: #ffffff; ">
        <div style="padding: 16px 0px;font-size:12px;color: #ef2147;display: flex;flex-direction: row-reverse;justify-content: center;align-items: center">
          <div><img src="https://ddmallimg.ddmg.com/public/assets/img/index/c_p_special/right.svg"></div>
          <div style="height: 30px;line-height: 30px;display: inline-block;margin:0px 12px">交易三大服务保障</div>
          <div><img src="https://ddmallimg.ddmg.com/public/assets/img/index/c_p_special/left.svg"></div>
        </div>
        <div style="width: 1200px;display: flex;flex-direction: row-reverse;justify-content: space-around;align-items: center;padding-bottom:20px">
          <div style="height: 41px;display: flex;justify-content: center">
            <img
              src="https://ddmallimg.ddmg.com/public/assets/img/index/c_p_special/title_text1.svg"
              style="width: 41px;height: 41px;"
            >
            <div style="margin-left: 20px">
              <div style="margin-top: 0px;font-size: 16px;color:#333333">以诚为本</div>
              <div style="font-size: 12px;color: #999999;margin-top:8px">开拓创新、执行高效、追求完美</div>
            </div>
          </div>
          <div style="height: 41px;display: flex;justify-content: center">
            <img
              src="https://ddmallimg.ddmg.com/public/assets/img/index/c_p_special/title_text2.svg"
              style="width: 41px;height: 41px;"
            >
            <div style="margin-left: 20px">
              <div style="margin-top: 0px;font-size: 16px;color:#333333">资源保证</div>
              <div style="font-size: 12px;color: #999999;margin-top:8px">企业认证、诚信、会员三大体系保证</div>
            </div>
          </div>
          <div style="height: 41px;display: flex;justify-content: center">
            <img
              src="https://ddmallimg.ddmg.com/public/assets/img/index/c_p_special/title_text3.svg"
              style="width: 41px;height: 41px;"
            >
            <div style="margin-left: 20px">
              <div style="margin-top: 0px;font-size: 16px;color:#333333">货真价实</div>
              <div style="font-size: 12px;color: #999999;margin-top:8px">价格真实有效,杜绝虚假交易</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="出价明细"
      :visible.sync="dialogVisible"
      width="70%"
    >

      <div style="margin: 10px 35px;">
        <div style="color: #444444; font-size: 14px;">
          <span>出价时间 : {{ this.dialogList.offerTime}} </span>
          <span style="margin-left: 70px;">报价方式 :
            <span v-if="this.dialogList.offerMode == 1">一口价</span>
            <span v-if="this.dialogList.offerMode == 2">网价浮动</span>
          </span>
        </div>
        <div style="color: #444444; font-size: 14px;position: relative;padding-top:10px">
          <span>报价说明 : {{ this.dialogList.offerComment}}</span>
        </div>
      </div>
      <div style="padding:35px;">
        <table
          class="table"
          style="border: 1px solid #E3E3E3;text-align: center;margin-bottom: 0;width: 100%; max-width: 100%;"
        >
          <tr>
            <th style="padding-left: 16px!important;">商品信息</th>
            <th style="text-align: center;">采购量</th>
            <th style="text-align: center;">产地</th>
            <th style="text-align: center;">
              <span v-if="this.dialogList.offerMode == 2">浮动单价</span>
              <span v-else>单价</span>
            </th>
            <th style="text-align: center;">
              <span v-if="this.dialogList.offerMode == 2">浮动总额</span>
              <span v-else>总金额</span>
            </th>
          </tr>
          <tr v-for="(item,index) in this.dialogList.details">
            <td style="text-align:left;padding-left: 16px!important;">
              <div style="margin-top: 15px;">{{item.productName}} {{item.material}}&nbsp;&nbsp;{{item.specifications}}</div>
              <div style="margin-bottom: 15px;">{{item.originPlace}}&nbsp;&nbsp;<span style="margin-left: 8px;">{{item.measurementMethod}}</span></div>
            </td>
            <td>{{item.quantity}}吨/{{item.weight}}吨</td>
            <td>{{item.originPlace}}</td>
            <td>{{item.offerPrice}}</td>
            <td>{{item.offerAmount}}</td>
          </tr>
        </table>
        <div style="height: 43px;line-height: 43px;background-color: #FAFAFA;border: 1px solid #DBDBDB; border-top:none;text-align: right;font-size: 14px;">
          <span style="margin-right: 64px;">合计</span>
          <span style="margin-right: 36px;">采购重量<span style="color: #fa8c15;font-weight: 600;margin-left: 8px;">
              {{ this.dialogList.weight }}
            </span></span>
          <span style="margin-right: 44px;">
            <span v-if="this.dialogList.offerMode == 2">浮动</span>总额<span style="color: #FA8C15;font-weight: 600;margin-left: 8px;">
              {{ this.dialogList.offerAmountSum }}
            </span>
          </span>
        </div>
      </div>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog>
  </comprehensive>
</template>

<script>
import comprehensive from "../../components/comprehensive.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: 'offerDetails',
  components: {
    comprehensive,
  },
  data () {
    return {
      dialogVisible: false,
      type: true,
      p_name: "",
      cd_name: "",
      jg_name: "",
      font_length: 0,
      offer_comment: "",
      dialogList: {},
      dialogWeight: "",
      dialogPrice: 0,


    };
  },
  created () {
    this.id = this.$route.query.id;
    if (this.$route.query.type == '1') {
      this.type = false;
    } else {
      this.type = true;
    }
    this.getOfferDetailsInfo(this.id).then(() => {
      let totel = 0
      this.dataDetails.forEach((item) => {
        totel += item.offerAmount * 1;
      });
      this.data.amountTotel = totel.toFixed(2);
    });

  },
  methods: {
    ...mapActions("ddmg/bid", ["getOfferDetailsInfo", "submit"]),
    onGoBid () {
      this.$router.push('/inquiry')
    },
    onGoHome () {
      this.$router.push('/index')
    },
    bidDetails (item) {
      this.dialogList = item;
      this.dialogVisible = true
    },
    //输入框字数
    fontChange () {
      if (this.offer_comment.length > 200) {
        this.$message("竞价字数不能超过200！");
        this.offer_comment.length == 200;
      }
      this.font_length = this.offer_comment.length;
    },
    //单价编辑
    priceChange (item) {
      item.offerAmount = (item.offerPrice * 1 * (item.weight * 1)).toFixed(2);
      let totel = 0
      this.dataDetails.forEach((item) => {
        totel += item.offerAmount * 1;
      });
      this.data.amountTotel = totel.toFixed(2);
    },
    //批量编辑
    plfz () {
      if (this.p_name == "") {
        this.$message("请选择需要批量赋值的品名！");
      } else {
        console.log("##plfz00=>", this.p_name);
        this.data.amountTotel = 0;
        this.dataDetails.forEach((item) => {
          // if (item.productName == this.p_name) {
          //   item.offerOriginPlace = this.cd_name;
          //   if (this.jg_name != "") {
          //     item.offerPrice = this.jg_name;
          //   }
          // }
          this.p_name.forEach(pitem => {
            if (item.productName == pitem) {
              item.offerOriginPlace = this.cd_name;
              if (this.jg_name != "") {
                item.offerPrice = this.jg_name;
              }
            }
          })
          item.offerAmount = item.offerPrice * 1 * (item.weight * 1);
          this.data.amountTotel += (item.offerAmount * 1);
        });
      }
    },
    //提交
    upData () {
      let is = true;
      if (this.dataDetails.find(obj => obj.offerPrice == "")) {
        this.$message("请输入价格！");
        is = false;
        return false;
      }
      if (this.dataDetails.find(obj => obj.offerOriginPlace == "")) {
        this.$message("请输入产地！");
        is = false;
        return false;
      }
      if (is) {
        this.data.offerComment = this.offer_comment;
        this.data.activityId = this.data.id;
        this.dataDetails.map(obj => {
          obj.activityDetailId = obj.id
        })
        let body = {
          head: this.data,
          details: this.dataDetails,
        };
        this.submit(body).then((res) => {
          if (res.msgcode === 1) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        });
      }
    },
    //立即竞价
    openOffer () {
      this.type = false;
    },
    //返回
    goback () {
      this.type = true;
    },
  },
  mounted () { },
  computed: {
    ...mapState("ddmg/bid", {
      data: (state) => state.offerDetailsInfo,
      dataDetails: (state) => state.offerDetails,
      timeStr2: (state) => state.timeStr2,
      PMlist: (state) => state.PMlist,
      pageLoading: (state) => state.pageLoading,
    }),
  },
};
</script>

<style lang="scss" scoped>
input:focus {
  outline: 0;
}
select:focus {
  outline: 0;
}
textarea:focus {
  outline: 0;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.table tr th {
  width: 101px;
  height: 50px;
  padding: 0 !important;
  line-height: 50px !important;
  background-color: #f6f6f6;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  text-align: center;
}
.gojj {
  width: 292px;
  height: 40px;
  background-color: #ffffff;
  color: #ef2147;
  border: 1px solid #ef2147;
  font-size: 16px;
  line-height: 28px;
  border-radius: 4px;
  margin-bottom: 18px;
  cursor: pointer;
}
.spanh5 {
  width: 26px;
  height: 26px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  line-height: 26px;
  color: #ff7e00;
  font-size: 14px;
  margin: 0px 5px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
}
.title_text_div {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  top: -30px;
  height: 520px;
  .div1 {
    width: 763px;
    height: 520px;
    border-right: 1px solid #f6f6f6;
    padding: 0px 24px;
    float: left;
    background-color: #ffffff;
    .div2 {
      border-bottom: 1px dashed #e3e3e3;
      margin-top: 37px;
      width: 758px;
      .div3 {
        margin: 12px auto;
        font-size: 14px;
        color: #333333;
        .div4 {
          display: inline-block;
          width: 110px;
          color: #333333;
          font-weight: 700;
        }
        .div5 {
          display: inline-block;
        }
        .div6 {
          font-size: 12px;
          color: #999999;
          margin-left: 15px;
          display: inline-block;
        }
      }
    }
  }
  .divr1 {
    width: 350px;
    height: 520px;
    float: right;
    padding-left: 30px;
    background-color: #ffffff;
    .divr2 {
      font-size: 18px;
      color: #333333;
      margin-top: 37px;
      font-weight: 600;
    }
    .divr3 {
      height: 70px;
      margin-top: 27px;
      img {
        width: 70px;
        height: 70px;
        float: left;
      }
      .divr4 {
        padding-left: 20px;
        font-size: 14px;
        color: #999999;
        float: left;
        width: 200px;
        margin-top: 12px;
        span {
          display: inline-block;
          width: 70px;
          text-align: right;
          margin-right: 10px;
        }
      }
    }
    .divr5 {
      width: 310px;
      height: 246px;
      margin-top: 50px;
      background: url(./img/2713.png);
      span {
        position: relative;
        left: 80px;
        top: 143px;
        font-size: 16px;
        color: #999999;
      }
    }
    .divr6 {
      width: 310px;
      height: 246px;
      margin-top: 50px;
      background: url(https://ddmallimg.ddmg.com/public/assets/img/newbid/271323.svg);
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 20px;
        color: #333333;
      }
    }
  }
}
.divb1 {
  width: 1120px;
  background-color: #ffffff;
  margin: 0px auto;
  padding: 24px 40px;
  margin-top: -16px;
  margin-bottom: 14px;
  .divb2 {
    margin: 0;
    font-size: 20px;
    border-bottom: 1px solid #efefef;
    padding-bottom: 22px;
    font-weight: 700;
    color: #333333;
    display: flex;
    .divb3 {
      width: 3px;
      height: 18px;
      background-color: #ef2147;
      border-radius: 6px;
      position: relative;
      left: -8px;
      top: 4px;
    }
  }
  .divb4 {
    width: 1120px;
    overflow: hidden;
    .table {
      border: 1px solid #efefef;
      margin-bottom: 0;
      width: 100%;
      background-color: transparent;
      border-collapse: collapse;
      border-spacing: 0;
      tr th {
        vertical-align: top;
        border-top: 1px solid #ddd;
        height: 50px;
        padding: 0 !important;
        line-height: 50px !important;
        background-color: #fafafa;
        font-size: 14px;
        color: #999999;
        font-weight: 400;
        border-color: #efefef !important;
        text-align: left !important;
        padding-left: 20px !important;
      }
      tr td {
        height: 50px;
        border-bottom: 1px solid #efefef;
        border-color: #efefef !important;
        line-height: 50px !important;
        padding: 0 !important;
        color: #333333;
        font-size: 14px;
        vertical-align: middle !important;
        text-align: left !important;
        padding-left: 20px !important;
      }
    }
  }
}
.title_img_div {
  width: 100%;
  height: 190px;
  background: url(./img/111562.png) no-repeat center;
  .div1 {
    width: 1200px;
    height: 190px;
    margin: 0 auto;
    padding-top: 17px;
    .nav_box {
      color: #ffffff;
      font-size: 14px;
      span {
        font-size: 14px;
        margin: 16px 0px;
        color: white;
        cursor: pointer;
      }
      span:hover {
        color: #ef2147ff;
      }
    }
  }
  .div2 {
    width: 1200px;
    height: 100px;
    .div3 {
      float: left;
      width: 70%;
      .div4 {
        font-size: 24px;
        color: #ffffff;
        margin-top: 33px;
        height: 32px;
        display: flex;
        align-items: center;
        span {
          font-size: 24px;
          line-height: 32px;
          color: #ffffff;
        }
        .div5-5 {
          height: 32px;
          background: url(./img/jjok.png) no-repeat left;
          width: 155px;
          position: relative;
          margin-left: 10px;
          display: inline-block;
        }
        .div5-67 {
          height: 32px;
          background: url(./img/2704.png) no-repeat left;
          width: 155px;
          position: relative;
          margin-left: 10px;
          display: inline-block;
        }
        .div5-3 {
          height: 32px;
          background: url(./img/2702.png) no-repeat left;
          width: 155px;
          position: relative;
          margin-left: 10px;
          display: inline-block;
        }
        .div5-2 {
          height: 32px;
          background: url(./img/205687.png) no-repeat left;
          width: 82px;
          position: relative;
          margin-left: 10px;
          display: inline-block;
        }
      }
      .div6 {
        font-size: 14px;
        color: #ffffff;
        margin-top: 15px;
      }
    }
    .div7 {
      float: right;
      width: 300px;
      text-align: center;
      .div8 {
        color: #ffffff;
        font-size: 16px;
        margin-top: 18px;
        margin-bottom: 8px;
        font-weight: 700;
      }
      .div9 {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
}
</style>
